@import 'rbx/index';

html {
  overflow-y: auto; }

body {
  --padding: 10px;
  --borderwidth: 5px;
  --totalspace: calc( 2 * calc(var(--padding) + var(--borderwidth)) );
  --vmin-minus-padding: calc( calc(var(--vmin-source) - var(--totalspace)) / 100); }

